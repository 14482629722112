import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Favicon from './Favicon.png';
import Error from './Error.js';


function TheApp() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <TheApp />
    <Helmet>
  <link
    rel="icon"
    type="image/png"
    sizes="32x32"
    href={Favicon}
  />
</Helmet>

  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
