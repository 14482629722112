import React from 'react';
import './Navbar.css';
import { Menu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import { Dropdown } from '@mui/base/Dropdown';
import MenuIcon from '@mui/icons-material/Menu';

function Navbar() {
    return (
        <div>
          <nav>
                <a className="ReturnHome" href="/" aria-label="Return Home"> </a>
                <Dropdown>
                  <MenuButton className="DropDownButton">
                      <MenuIcon className="MenuSymbol" style={{ fontSize: '48px' }} ></MenuIcon>
                  </MenuButton>
                  <Menu>
                    <div className="MenuBarDiv">
                    </div>
                  </Menu>
                </Dropdown>
          </nav>
        </div>
    )
}

export default Navbar;
