
import './App.css';
import Navbar from './Navbar';

function App() {
  return (
    <div className="App">
      <header className="appBackground">
        <Navbar className="Navbar" />
        <div className="homeContent">
            <div className="logoHome"></div>
        </div>
      </header>
    </div>
  );
}

export default App;
