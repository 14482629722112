import React from 'react';
import './Error.css';
import NavBar from './Navbar.js';

function Error() {
    return (
        <div className="ErrorPage">
            <div className="ErrorBackground">
                <NavBar />
                <div className="errorContent">
                    <div className="rotatingError"></div>
                    <h1 className='errorH1'>Error 404: Page Not Found!</h1>
                </div>
            </div>
        </div>
    )
}

export default Error
